'use client';

import { useCallback, useInsertionEffect, useRef } from 'react';
import { useSocket, useSocketEvent } from 'socket.io-react-hook';
import type { ServerToClient } from '@inderes/videosync-types';

type UseSocketServiceOptions = {
  url: string;
};

type UseSocketService = ReturnType<typeof useSocketService>;

export const useSocketService = ({ url }: UseSocketServiceOptions) => {
  return useSocket(url, {
    transports: ['websocket'],
  });
};

// const useEffectEvent = (callback: any) => {
//   const ref = useRef(callback);

//   ref.current = callback;

//   return (...args: any) => {
//     ref.current(...args);
//   };
// };

// The useEvent API has not yet been added to React,
// so this is a temporary shim to make this sandbox work.
// You're not expected to write code like this yourself.
const useEffectEvent = (callback: any) => {
  const ref = useRef<any>(null);
  useInsertionEffect(() => {
    ref.current = callback;
  }, [callback]);
  return useCallback((...args: any) => {
    const f = ref.current;
    return f(...args);
  }, []);
};

/**
 *
 * NOTE: onMessage needs to be a useCallback function
 */
export const useSocketServiceEvent = <T extends keyof ServerToClient>(
  socket: UseSocketService['socket'], // TODO: type
  event: T,
  onMessage: (message: ServerToClient[T]) => void,
) => {
  // hold references to message
  const messageRef = useRef<T | null>(null);

  const onMessageCallback = useEffectEvent(onMessage);

  const { lastMessage, sendMessage } = useSocketEvent<T>(socket, event, {
    onMessage: (message) => {
      // Check if message is null or undefined (this allows for example zero or empty string)
      if (message === null || message === undefined) return; 
  
      // Skip processing if this message is identical to the previous one
      if (messageRef.current === message) return;
      
      console.log(`socketMessage - ${event}`, message);
  
      // Update the reference to the latest message
      messageRef.current = message;
  
      // Execute the provided onMessage callback function
      onMessageCallback(message);
    },
  });

  return {
    lastMessage,
    sendMessage,
  };
};
