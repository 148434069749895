import(/* webpackMode: "eager", webpackExports: ["CategoryAllEventsBlock"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/category-all-events/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryEvents"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/category-events/category-events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryEventsBlock"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/category-events/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventBanner"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/event-banner/event-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventContent"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/event-content/event-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeadingBlock"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationMenu"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/navigation-menu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBlock"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/search/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextContentBlock"] */ "/app/apps/videosync-event-site/app/_puck/library-site-blocks/text-content/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Client"] */ "/app/apps/videosync-event-site/app/(library-site)/[locale]/site/[accountSlug]/[[...pageSlug]]/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/videosync-event-site/app/(library-site)/[locale]/site/[accountSlug]/[[...pageSlug]]/localechange.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JotaiProvider"] */ "/app/apps/videosync-event-site/app/(library-site)/jotai-service.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/videosync-event-site/app/(library-site)/locales/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["SlideCarousel"] */ "/app/packages/react-components/src/SlideChanger/components/SlideCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ActiveSlide"] */ "/app/packages/react-components/src/SlideChanger/components/slides/ActiveSlide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slide"] */ "/app/packages/react-components/src/SlideChanger/components/slides/Slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useSlideChangerKeyboardEvents"] */ "/app/packages/react-components/src/SlideChanger/hooks/use-handle-keyboard-events.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/react-components/src/socket-service/socket-service-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/react-components/src/socket-service/socket-service.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/alert-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/color-picker.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/command.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/form.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/hooks.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/hover-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/label.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/legacy-tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/navigation-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/popover.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/progress.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/radio-group.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/resizable.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/select.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/separator.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/switch.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/videosync-ui/src/use-toast.tsx");
