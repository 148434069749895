import z from 'zod';

const envSchema = z.object({
  APP_ENV: z.enum(['local', 'development', 'staging', 'production']).default('local'),
  VIDEOSYNC_DOMAIN: z.string().default('http://videosync.localhost'),
  VIDEOSYNC_OLD_API_URL: z.string().default('http://videosync.localhost'),
  VIDEOSYNC_MONGODB_URL: z.string().default('mongodb://localhost:27017/test'),
  VIDEOSYNC_CDN: z.string().default('http://cdn.videosync.localhost'),
  VIDEOSYNC_SOCKET_SERVICE: z.string().default('ws://socketservice.videosync.localhost'),
  VIDEOSYNC_VOD_STREAMING: z.string().default('http://localhost:1935/vod'),
  BITMOVIN_LICENSE_KEY: z.string().default('19c1f37b-405a-4b7e-ae74-7fc46e24a2ec'),
  YOUBORA_PLAYERSERVICE_URL: z.string().default('https://data.npaw.videosync.fi/'),
  VIDEOSYNC_REDIS_HOST: z.string().default('127.0.0.1'),
  BACKSTAGE_API_URL: z.string().default('http://videosync.localhost:3001'),
  VIDEOSYNC_REDIS_PORT: z.string().default('6379'),
  TURBO_BRIDGE_BASE_URL: z.string().default('https://api.turbobridge.com'),
  TURBO_BRIDGE_EMAIL: z.string().default(''),
  TURBO_BRIDGE_PASSWORD: z.string().default(''),
  TURBO_BRIDGE_ACCOUNT_ID: z.string().default(''),
  TURBO_BRIDGE_PARTNER_ID: z.string().default('turbobridge'),
  IR_EVENTS_DOMAIN: z.string().default('videosync.localhost'),

  /* Email */
  AWS_SES_REGION: z.string().default('eu-west-1'),
  AWS_SES_ACCESS_KEY_ID: z.string().default(''),
  AWS_SES_SECRET_ACCESS_KEY: z.string().default(''),
  AWS_SES_SENDING_RATE: z.string().default('14'),
  EMAIL_FROM_DOMAIN: z.string().default('videosync.fi'),

  DAILY_URL: z
    .enum(['https://videosync-dev.daily.co/', 'https://flik.daily.co/'])
    .default('https://videosync-dev.daily.co/'),
  JWT_SECRET: z.string().default('2zK4SbHQu67re53m'),
  EVENT_SITE_URL: z.string().default('http://player.videosync.localhost:3006'),
  LIBRARY_DOMAINS_CONFIG: z
    .string()
    .optional()
    .transform((value) => {
      if (!value) return value;

      if (value.length === 0) return undefined;

      const domains = value.split(',').map((item) => item.split(':'));
      return domains;
    }),
});

export function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';

  // assume localhost
  return ENV.EVENT_SITE_URL;
}

export const ENV = envSchema.parse(process.env);
