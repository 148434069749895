import { Account, ChatboardMessage, Chatboard, Event, Prisma } from '@inderes/videosync-database';
import { z } from 'zod';

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & object;

// this is Int in the database, so it's easier to just use enum, instead of const as const
export enum EventState { // Old names commented
  LIVE = 0, // Live
  PUBLISH_RECORDING = 1, // (Public) On Demand
  EDIT_RECORDING = 2, // Hidden On Demand
  SHARE = 3, // Pre Live / Hidden Live
  LIVE_PAUSED = 6, // Pause
  BUILD = 7, // did not exist previously -> 404 page for viewers
  REGISTRATION = 8, //Registration (virtual state, not really stored in database!)
}

export enum EventType {
  VIDEO = 'video',
  AUDIO = 'audio event',
}
export enum HiddenImageOptionTemplateType {
  BACKGROUND = 'BACKGROUND',
  SEPARATE_IMAGE = 'SEPARATE_IMAGE',
}
export enum RegistrationImageOptionTemplateType {
  BACKGROUND = 'BACKGROUND',
  SEPARATE_IMAGE = 'SEPARATE_IMAGE',
}

export enum ParticipantProfilingOptions {
  HLS = 'HLS',
  REALTIME = 'REALTIME',
}

export enum ChatboardTypes {
  OWN_SECTION = 'own-section',
  QNA = 'qna',
  PRESENTATION = 'presentation',
}

export const Analytics = z.object({
  username: z.string().optional(),
  obfuscateIp: z.boolean().optional(),
  accountId: z.string().optional(),
  accountUrlName: z.string().optional(),
  eventId: z.string(),
  eventUrlName: z.string(),
  title: z.string(),
  viewerProfile: z.string().optional(),
  accountCode: z.string(),
  host: z.string(),
  playerLocation: z.string(),
  liveChannel: z.string().optional(),
  autoplay: z.boolean().optional(),
  isLive: z.boolean(),
  estimatedBitrate: z.number().optional().nullable(),
  isIos: z.boolean(),
  isAndroid: z.boolean(),
  isEmbedded: z.boolean().optional(),
});
export type AnalyticsObject = z.infer<typeof Analytics>;

// this is Int in the database, so it's easier to just use enum, instead of const as const
export enum ChatboardMessageState {
  'PUBLISHED' = 1,
  'PENDING' = 2,
  'DISCARDED' = 3,
  'PRESENTER' = 4,
  'BACKSTAGE' = 5,
}

export enum WowzaStreamState {
  'NO_STREAM' = 'No stream',
  'INTERRUPTED' = 'Interrupted',
  'RECEIVING_STREAM' = 'Receiving stream',
  'RECEIVING_STREAM_AND_RECORDING' = 'Receiving stream & recording',
}

// Create modified version of ChatboardMessage.responses where reactions has count instead of userViewTokens
export type ChatboardMessageResponse = Omit<ChatboardMessage['responses'][0], 'reactions'> & {
  reactions: {
    emoji: string;
    id: string;
    count: number;
  }[];
};

// Create modified ChatboardMessage type where reactions has count instead of userViewTokens
export type ChatboardMessageWithCount = Omit<ChatboardMessage, 'reactions' | 'responses'> & {
  reactions: {
    emoji: string;
    id: string;
    count: number;
  }[];
  responses: ChatboardMessageResponse[];
};

export const eventWithAccountSelector = {
  id: true,
  urlName: true,
  title: true,
  publishingDate: true,
  state: true,
  dailyProducerRoom: true,
  // relations
  account: {
    select: {
      id: true,
      name: true,
      urlName: true,
      allowBreakoutRoom: true,
    },
  },
  webStudioCodes: true,
};

// NOTE: when editing this, check also that
// integrations/inderes/schema.ts -> EventLinksResponse
// has the same structure
export type EventLinks = {
  eventAdmin: string;
  baseAccountUrl: string;
  view: string;
  preview: string;
  previewWithStateSelector: string;
  dashboard: string;
  shareDashboard: string;
  onsite: string;
  presenter: string;
  producer: string;
  liveControlRoomUrl: string;
  newEventSiteAccountUrl: string;
  newEventSiteEmbed: string;
  newEventSiteEditor: string;
  teleconference?: {
    controlPanel: string;
    registration: string;
    remoteCall: string;
    webCall: string;
    advancedControlPanel: string;
    dialInSite: string;
  };
  webstudio?: {
    url: string;
    token: string;
    role: string;
  }[];
};

export type WithLinks<T> = T & {
  links: EventLinks;
};

export type EventWithAccount = Prisma.EventGetPayload<{
  select: typeof eventWithAccountSelector;
}>;

export type Chat = {
  chatboard: {
    board: Prisma.ChatboardGetPayload<{ include: null }>;
    show: boolean;
  };
};

export type ExtendedEvent = Omit<
  Prisma.EventGetPayload<{
    include: {
      account: {
        select: {
          id: true;
          name: true;
          urlName: true;
          alternativeAccountUrl: true;
          allowBreakoutRoom: true;
        };
      };
      liveChannel: true;
      webStudioCodes: true;
    };
  }>,
  'chatboard'
> &
  Chat;

export type EventWithLiveChannel = Prisma.EventGetPayload<{
  include: {
    liveChannel: true;
  };
}>;

export interface SystemAdminGetCalendar200 {
  events: EventWithAccount[];
}

export interface AccountsWithEvents extends Account {
  events: Event[];
}

export type ChatboardWithMessages = Prisma.ChatboardGetPayload<{
  include: {
    messages: true;
  };
}>;

export interface GetChatboardWithMessages200 {
  chatboard: Chatboard;
  messages: ChatboardMessage[];
  totalMessageCount: number;

  layout: any;
}

export interface EventLiveToolStartRecordingData {
  eventId: string;
  filename: string;
  log: Event['liveToolData']['log'];
  session: Event['liveToolData']['sessions'];
}

export type SystemAdminGetUsers200 = Prisma.UserGetPayload<{
  select: {
    id: true;
    email: true;
    createdAt: true;
    firstName: true;
    lastName: true;
    username: true;
  };
}>[];

// export interface VideosyncToken {
// user: {
//   passwordChanged: '2022-01-31T09:13:02.603Z';
//   authMethods: ['pwd', 'o365'];
//   enabled: true;
//   groups: [];
//   firstName: 'MIkko';
//   lastName: 'Pitkänen';
//   email: 'mikko.pitkanen@inderes.fi';
//   tags: [
//     {
//       role: 'normal';
//       _id: '61f8fdd7b9b3f00011c52ffc';
//       tag: 'test';
//     }
//   ];
//   username: 'mikko.pitkanen@inderes.fi';
//   lastLoginAt: '2022-11-21T07:47:25.946Z';
//   createdAt: '2022-05-09T11:11:34.576Z';
//   sub: '61f8fdd7b9b3f00011c52ffb';
//   tokentype: 'authorization';
//   isAdmin: true

// };
// account: {
//   _id: '565c03ebe9678db50404fc32';
//   name: 'dev';
//   urlName: 'dev';
//   created: '2015-11-30T08:08:11.113Z';
//   alternativeAccountUrlCategories: [];
//   accountRole: 'accountAdmin';
// };
// iat: 1671289964;
// exp: 1671894764;
// aud: 'videosync.fi';
// }

export const OldVideosyncAccountRole = {
  normal: 'normal',
  accountAdmin: 'accountAdmin',
} as const;
export type OldVideosyncAccountRole = keyof typeof OldVideosyncAccountRole;

export const OldVideosyncTokenSchema = z.object({
  user: z.object({
    passwordChanged: z.string().datetime().optional(),
    authMethods: z.array(z.string()),
    enabled: z.boolean(),
    groups: z.array(z.string()),
    tags: z.array(
      z.object({
        tag: z.string(),
        role: z.union([z.literal('normal'), z.literal('accountAdmin')]),
      }),
    ),
    createdAt: z.string().datetime(),
    username: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    lastLoginAt: z.string().datetime(),
    sub: z.string(),
    tokentype: z.union([z.literal('authentication'), z.literal('authorization')]),
    isAdmin: z.boolean(),
  }),
});
export type OldVideosyncToken = z.infer<typeof OldVideosyncTokenSchema>;

export const VideosyncTokenSchema = z.object({
  scope: z.string(),
});
export type VideosyncToken = z.infer<typeof OldVideosyncTokenSchema>;

export const InsertSubtitlePayloadSchema = z.object({
  eventId: z.string(),
  subtitle: z.object({
    filename: z.string(),
    label: z.string(),
    lang: z.string(),
    mimetype: z.string(),
    path: z.string(),
    size: z.number().int(),
  }),
});

export type InsertSubtitlePayload = z.infer<typeof InsertSubtitlePayloadSchema>;

export const duplicateEventPayloadSchema = z.object({
  target: z.object({
    category: z.string(),
  }),
  include: z.object({
    attachments: z.boolean(),
    chapters: z.boolean(),
    chat: z.boolean(),
    metadata: z.boolean(),
    polls: z.boolean(),
    previewImg: z.boolean(),
    regEmailContent: z.boolean(),
    slides: z.boolean(),
  }),
  newData: z.object({
    title: z.string(),
    publishingDate: z.string().datetime(),
    urlName: z.string(),
    state: z.number().optional(),
    irEvent: z.boolean().optional(),
    isNewEventSite: z.boolean().optional(),
  }),
});

export type DuplicateEventPayload = z.infer<typeof duplicateEventPayloadSchema>;

export * from './socket';
export * from './web-studio';
export * from './amberscript';
export * from './turbobridge';
